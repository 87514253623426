.container {
    
    display:flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    min-height: 100vh;    
    padding: 0; 
    margin: 0; 
    background: linear-gradient(#000000, #029b87);
     max-width: 100% !important;
}

.containerMain{
 display: flex;
 flex-direction: column;
 align-items: center;
}

.button-container {
    display: flex;
    justify-content: space-between; 
    padding: 1rem; 
    width: 100%;
  }
  
  .btn {  
    width: 100% ;    
     
  }

  .button-container button {
    flex: 1; 
    margin: 0 5px; 
  }
  
  .button-container button:first-child {
    margin-left: 0; 
  }
  
  .button-container button:last-child {
    margin-right: 0; 
  }


.title{     
    font-size: 540%;
    color: beige;
    animation: flipTitle 2s;
}

@keyframes flipTitle {
    from{
        transform: rotateX(90deg);
    }
    to{
        transform: rotateX(0deg);
    }
}

  .top-image {
    position: fixed; 
    top: 10px; 
    left: 10px; 
    width: 10%;
    height: auto; 
    z-index: 1000; 
      animation: flipTitle 2s;
  }

.main{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: #f1f1f1;
width: 500px;
border-radius: 8px;
}

.main h2{
    margin: 16px 0;
    font-size: 39px;
}

.main span{
margin-bottom: 16px;
font-weight: bold;    
}


@media (max-width: 520px){
    .title{
        font-size: 60px;
    }

    .main h2{
        font-size: 28px;
    }
    .main{
        width: 80%;
        min-height: 250px;
    }   
    .btn {
      width: 40% ;
      min-height: 40% ;
      font-size: 40% ;     
    }
    
}

.editorBox { 
    
  background-color: #201f1fb5;
    height: 40%; 
    width: 40%; 
    border-radius: 8px;  
    margin: 1 15px;   
}

.editorBox .rich-editor { 
    padding: 2rem;    
   margin: -1rem;
}

.rich-editor .rdw-editor-main { 
  background-color: #fff;
}

.editor-rico .DraftEditor-editorContainer { 
    padding: 1rem; 
}

.draft-editor-wrapper { 
    border: 1px solid #000000; 
}

.rdw-option-wrapper[title="Link"] {
  color: #000000; 
}

.demo-editor {
    max-height: 200px; 
    overflow-y: auto; 
    border: 1px solid #ccc;
    padding: 10px; 
  }
  
  .rdw-block-wrapper {
    display: none;
}

.rdw-fontfamily-wrapper{
  display: none;
}

.rdw-fontsize-wrapper{
  display: none;
}

.rdw-link-wrapper {
  display: none;
}

.rdw-option-wrapper[title="Link"] {
  display: none;
}

.rdw-colorpicker-wrapper[title="Color Picker"] {
  display: none;
}

.rdw-option-wrapper[title="Monospace"] {
  display: none;
}

  .rdw-option-wrapper[title="Strikethrough"] {
    display: none;
}

  .rdw-option-wrapper[title="Remove"] {
    display: none;
}

  .rdw-emoji-wrapper[title="Emoji"] {
    display: none;
}

.rdw-option-wrapper[title="Image"] {
  display: none;
}

.rdw-dropdown-selectedtext[title="Font"] {
  display: none;
}

.rdw-dropdown-selectedtext[title="Font Size"] {
  display: none;
}

.rdw-dropdown-selectedtext[title="Block Type"] {
  display: none;
}

.rdw-option-wrapper[title="Embedded"] {
  display: none;
} 

  .scrollable {
    overflow-y: auto; 
  }

.gridview {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; 
    width: 40%; 
  }
  
  .gridview h2 {
    margin-bottom: 15px;
  }
  
  .close-button {
    background: none; 
    border: none; 
    color: #000000; 
    font-size: 20px;
    position: absolute; 
    top: 10px; 
    right: 10px; 
    cursor: pointer; 
  }
  
  .close-button:hover {
    color: red; 
  }
  
  .table-container {
    max-height: 300px; 
    overflow-y: auto; 
    margin-top: 10px; 
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #000000;
  }
  
  th {
    background-color:  #f1f1f1;
    font-weight: bold;
  }
  
  tr:hover {
    background-color: #029b87;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 20%;
  }
  
  .close {   
    float: right;
    font-size: 20px;
    font-weight: bold;
    position: absolute; 
    top: 10px; 
    right: 10px; 
    cursor: pointer; 
  }